const guide = {
    start_building: '시작하기',
    get_started: '시작하기',
    categories: {
        featured: '인기와 당신을 위한',
        Traditional: '전통적인 웹 앱',
        SPA: '싱글 페이지 앱',
        Native: '네이티브',
        MachineToMachine: '기계 간 통신',
        Protected: 'SDK 통합이 없는 보호',
        ThirdParty: '타사 앱',
    },
    filter: {
        title: '프레임워크 필터',
        placeholder: '프레임워크 검색하기',
    },
    checkout_tutorial: '튜토리얼 {{name}} 확인하기',
    do_not_need_tutorial: '튜토리얼이 필요하지 않다면, 프레임워크 가이드 없이 계속할 수 있습니다.',
    finish_and_done: '완료',
    cannot_find_guide: '가이드를 찾을 수 없습니다.',
    describe_guide_looking_for: '찾고 계신 가이드에 대해 설명해주세요.',
    request_guide_successfully: '요청이 성공적으로 제출되었습니다. 감사합니다!',
    app: {
        select_framework_or_tutorial: '프레임워크 또는 튜토리얼 선택',
        guide_modal_title: 'SDK 및 가이드로 시작하기',
        modal_subtitle: '사전 빌드된 SDK와 튜토리얼로 앱 개발 프로세스를 바로 시작하세요.',
        select_a_framework: '프레임워크 선택하기',
        continue_without_framework: '프레임워크 없이 앱 만들기',
        describe_guide_looking_for_placeholder: '예시: Angular 앱에 Logto 통합하고 싶습니다.',
    },
    api: {
        modal_title: '튜토리얼로 시작하기',
        modal_subtitle: '사전 빌드된 튜토리얼로 앱 개발 프로세스를 바로 시작하세요.',
        select_a_tutorial: '튜토리얼 선택하기',
        continue_without_tutorial: '튜토리얼 없이 계속하기',
        describe_guide_looking_for_placeholder: '예시: Deno를 사용하여 API 보호하기',
    },
};
export default Object.freeze(guide);
