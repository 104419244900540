const menu = {
    profile: '프로필',
    language: '언어',
    appearance: {
        label: '모습',
        light: '라이트 모드',
        dark: '다크 모드',
        system: '시스템과 동기화',
    },
    sign_out: '로그아웃',
};
export default Object.freeze(menu);
