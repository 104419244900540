const logs = {
    page_title: '감사 기록',
    title: '감사 기록',
    subtitle: '관리자나 사용자의 인증 기록을 확인해 보세요.',
    event: '활동',
    user: '사용자',
    application: '어플리케이션',
    time: '시간',
    filter_by: '필터',
};
export default Object.freeze(logs);
