const log_details = {
    page_title: '감사 로그 세부 정보',
    back_to_logs: '감사 기록으로 돌아가기',
    back_to: '{{name}}으로 돌아가기',
    success: '성공',
    failed: '실패',
    event_key: '이벤트 키',
    application: '어플리케이션',
    ip_address: 'IP 주소',
    user: '사용자',
    log_id: '기록 ID',
    time: '시간',
    user_agent: '사용자 Agent',
    tab_details: '상세',
    raw_data: '상세 데이터',
};
export default Object.freeze(log_details);
