const general = {
    placeholder: '플레이스홀더',
    submit: '제출',
    skip: '건너뛰기',
    next: '다음',
    back: '뒤로',
    retry: '재시도',
    done: '완료',
    search: '검색',
    search_placeholder: '검색',
    clear_result: '결과 지우기',
    save: '저장',
    save_changes: '변경 내용 저장',
    saved: '저장됨',
    discard: '취소',
    loading: '로딩 중...',
    redirecting: '리다이렉트 중...',
    add: '추가',
    added: '추가됨',
    cancel: '취소',
    confirm: '확인',
    check_out: '확인하기',
    create: '생성',
    set_up: '설정',
    customize: '사용자화',
    enable: '활성화',
    reminder: '리마인더',
    edit: '편집',
    delete: '삭제',
    deleted: '삭제됨',
    more_options: '더 많은 설정',
    close: '닫기',
    copy: '복사',
    copying: '복사 중',
    copied: '복사됨',
    required: '필수',
    add_another: '새로 추가',
    create_another: '또 다른 것 만들기',
    deletion_confirmation: '정말로 {{title}}을/를 삭제할까요?',
    settings_nav: '설정',
    unsaved_changes_warning: '수정된 내용이 있어요. 정말로 현재 페이지를 벗어날까요?',
    leave_page: '페이지 나가기',
    stay_on_page: '페이지 유지하기',
    type_to_search: '검색어 입력',
    got_it: '알겠어요',
    continue: '계속하기',
    page_info: '{{min, number}}-{{max, number}} / {{total, number}}',
    learn_more: '더 알아보기',
    /** UNTRANSLATED */
    tab_error_one: '{{count, number}} error',
    tab_error_other: '{{count, number}} 오류',
    skip_for_now: '지금은 건너뛰기',
    remove: '삭제',
    visit: '방문하기',
    join: '참여하기',
    try_now: '지금 시도하기',
    multiple_form_field: '(복수 선택)',
    demo: '데모',
    unnamed: '이름없음',
    view: '보기',
    open: '열기',
    hide: '숨기기',
    unknown_error: '알 수 없는 오류가 발생했습니다. 나중에 다시 시도해주세요.',
    select: '선택',
    contact_us_action: '문의하기',
    description: '설명',
    name: '이름',
    add_field: '{{field}} 추가',
    create_field: '{{field}} 만들기',
    edit_field: '{{field}} 편집',
    delete_field: '{{field}} 삭제',
    coming_soon: '곧 출시 예정',
    or: '또는',
};
export default Object.freeze(general);
